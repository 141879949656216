@font-face {
  font-family: 'Object Sans Bold';
  src:
    url('/fonts/Object-Sans-Bold.woff2') format('woff2'),
    url('/fonts/Object-Sans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Object Sans Regular';
  src:
    url('/fonts/Object-Sans-Regular.woff2') format('woff2'),
    url('/fonts/Object-Sans-Regular.woff') format('woff');
  font-display: swap;
}
